.faq-section{
    background-color: #fff;
    /* padding: 150px 0; */
    padding: 50px 0;
}
.faq-section .heading {
    text-align: center;
}
.faq-cta{
    background-color: #CDE3FA;
    border-radius: 32px;
    padding-top: 82px;
    padding-bottom: 82px;
}
.faq-cta .content{
    margin-top: auto;
    margin-bottom: auto;
    width: 469px;
    display: flex;
    flex-direction: column;
    gap: 24px;
}
.section.faq-section .faq-cta .footer-logo {
    text-align: center;
}
.section.faq-section .faq-cta .footer-logo img {
    height: 319px;
    width: auto;
}

@media screen and (max-width: 576px) {
    .accordion-items, .accordion-items .accordion-content{
        width: 100%;
    }
    .section.faq-section .faq-cta .footer-logo img{
        width: 100%;
    }
    .accordion-toggle-btn{
        width: 46px;
        height: 46px;
    }
}