.our-product-section{
    padding: 100px 0;
    background-image: url('/src/assets/product-candle-bg.png');
}

.our-product-section .heading{
    text-align: center;
    margin-bottom: 100px;
}
.our-product-section .heading p{
    margin-top: 32px;
}

.our-products {
    display: flex;
    justify-content: space-between;
}
.our-products .card {
    width: 249px;
    height: 526px;
    padding: 32px 10px 48px 10px;
    text-align: center;
    border: 0;
}
div.learn-more{
    margin-top: auto;
}
.our-products .card button {
    width: fit-content;
    position: absolute;
    bottom: -24px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    padding: 12px 32px;
}
.our-products .image{
    margin-bottom: 24px;
}
.our-products .content h6{
    margin-bottom: 20px;
}
.section.our-product-section:before {
    content: '';
    position: absolute;
    bottom: -636px;
    inset-inline-start: 0;
    width: 902px;
    height: 902px;
    border-radius: 902px;
    opacity: 0.1;
    background: #5F00D9;
    filter: blur(170px);
}
.section.our-product-section:after {
    content: '';
    position: absolute;
    inset-inline-end: 0;
    width: 822px;
    height: 822px;
    border-radius: 822px;
    opacity: 0.1;
    background: #EBA900;
    filter: blur(170px);
    bottom: -636px;
}

@media screen and (max-width: 576px) {
    .our-products{
        flex-wrap: wrap;
        justify-content: center;
    }
    .our-products .card:not(:last-child){
        margin-bottom: 64px;
    }
    .section.our-product-section:before{
        width: 100%;
    }
}