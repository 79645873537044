.broker-calc {
    margin-bottom: 120px;
    margin-top: 100px;
}

.broker-calc .card {
    padding: 48px;
    background-color: transparent;
    width: 533px;
    height: 540px;
}

/* .broker-calc .card > * {
    margin-bottom: 24px;
} */
.broker-calc .card select {
    background: transparent;
    padding: 16px 24px;
    border-radius: 16px;
}

.broker-calc .card.clients {
    margin-left: auto;
}

.account-section .account-points .items {
    /* width: 533px;
    margin-left: auto; */
    text-align: center;
}

.broker-calc .card button {
    width: fit-content;
    margin-bottom: 0;
    margin-top: 32px;
}

.card.calculation {
    background-color: #FF4755;
    color: #fff;
}

.top-content,
.bottom-content {
    display: flex;
}

.top-content p,
.bottom-content h6 {
    margin-inline-start: auto;
}

.middle-content table {
    width: 100%;
    border-collapse: separate;
}

.middle-content table tr>* {
    padding-bottom: 24px;
}

.middle-content table tr>:last-child {
    text-align: end;
}

.slider-range {
    display: flex;
    margin-bottom: 16px;
}

.slider-range .most-value {
    margin-inline-start: auto;
}

.clients-count {
    display: flex;
    margin-bottom: 24px;
}

.clients-count p:last-child {
    margin-inline-start: auto;
}

.broker-calc .card .bottom-content {
    margin-top: auto;
    margin-bottom: 0;
}

.card.calculation .top-content {
    margin-bottom: 32px;
}

.card.clients h6 {
    margin-bottom: 32px;
}

label.p2 {
    margin-bottom: 8px;
}

.form-group {
    margin-bottom: 24px;
}

input[type="range"] {
    margin-bottom: 24px;
}

@media screen and (max-width: 576px) {
    .heading.text-center h1 {
        font-size: 32px;
    }

    .heading.text-center p.p2 {
        font-size: 16px;
    }

    .broker-calc {
        margin-top: 32px;
        margin-bottom: 32px;
    }

    .broker-calc .row>div:first-child {
        margin-bottom: 24px;
    }

    .section.account-section .row:nth-child(3)>div:first-child {
        margin-bottom: 32px;
    }

    .broker-calc .card,
    .account-section .account-points .items {
        width: 100%;
    }

    .account-section .account-points .row div:not(:last-child) .items {
        margin-bottom: 32px;
    }

    .broker-calc .card {
        padding: 40px 20px;
    }
}

.reward-table thead tr th:nth-child(2) {
    border-top-right-radius: 12px !important;
    border-top-left-radius: 0 !important;
    /* Reset for RTL */
}

.reward-table thead tr th:first-child {
    border-top-left-radius: 12px !important;
    border-top-right-radius: 0 !important;
    /* Reset for RTL */
}

/* Optional: Apply conditional styles for RTL specifically */
body[dir="rtl"] .reward-table thead tr th:nth-child(2) {
    border-top-left-radius: 12px !important;
    border-top-right-radius: 0 !important;
}

body[dir="rtl"] .reward-table thead tr th:first-child {
    border-top-right-radius: 12px !important;
    border-top-left-radius: 0 !important;
}




.reward-card {
    background: #F3F4F5;
    width: 718px;
    gap: 0px;
    border-radius: 20px;
    opacity: 1px;
    padding: 20px;
    margin-top: 50px;
    margin-right: auto;
}

.reward-card-two {
    background: #FF4755;
    ;
    width: 718px;
    gap: 0px;
    border-radius: 20px;
    opacity: 1px;
    padding: 20px;
    height: 116px;
    z-index: 1;
    position: relative;
    bottom: 14px;
    margin: auto;
}

.reward-card-three {
    background: #F3F4F5;
    width: 718px;
    gap: 0px;
    border-radius: 20px;
    opacity: 1px;
    padding: 15px;
    height: 116px;
    z-index: 2;
    position: relative;
    bottom: 28px;
    margin-left: auto;
}

.reward-card p,
.reward-card-two p,
.reward-card-three p {
    font-size: 19px;
    font-weight: 400;
    line-height: 28.88px;
    text-align: center;
}

.reward-card-two p {
    color: white;
}

.reward-card p span,
.reward-card-three p span {
    color: #FF4755;
    font-weight: 600;
}

.reward-card-two p span {
    color: white;
    font-weight: 600;
}

.reward-coins-img {
    z-index: 3;
    position: absolute;
    right: 0;
    top: 27%;
}

.coins-card-box {
    position: relative;
}

.account-section.new-section {
    position: relative;
}

.join-box {
    height: 391px;
    gap: 0px;
    border-radius: 26px;
    opacity: 1px;
    background: #FF4755;
    padding: 70px 40px;
}

.join-box h3 {
    color: white;
    font-size: 52px;
    font-weight: 600;
    line-height: 69.16px;
    text-align: center;
    margin-bottom: 40px;
}

.join-box p {
    color: white;
    font-size: 25px;
    font-weight: 400;
    line-height: 33.25px;
    text-align: center;
    margin-bottom: 30px;
}

@media screen and (max-width: 1200px) {
    .reward-coins-img {
        z-index: 3;
        position: absolute;
        right: 0;
        top: 56%;
        width: 211px;
    }
}

@media screen and (max-width: 536px) {
    .reward-coins-img {
        z-index: 3;
        position: absolute;
        right: 0;
        top: 52%;
        width: 211px;
    }
}


@media screen and (max-width: 962px) {

    .reward-card {
        background: #F3F4F5;
        border-radius: 8px;
        padding: 10px 0 20px;
        margin-top: 50px;
        width: 362px;
    }

    .reward-card-two {
        background: #FF4755;
        ;
        width: 355px;
        border-radius: 8px;
        padding: 10px;
        height: 73px;
        z-index: 1;
        position: relative;
        bottom: 14px;
        margin: auto;
        right: -16px;
    }

    .reward-card-three {
        background: #F3F4F5;
        width: 332px;
        border-radius: 8px;
        padding: 10px 0 20px;
        height: 85px;
        z-index: 2;
        position: relative;
        bottom: 28px;
        margin-left: auto;
    }

    body[dir="rtl"] .reward-table-2 tr td,
    body[dir="rtl"] .reward-table-2 tr th {
        font-size: 10px !important;
    }

    .reward-card p,
    .reward-card-two p,
    .reward-card-three p {
        font-size: 12px;
        font-weight: 400;
        line-height: 18.24px;
        text-align: left;
    }

    .reward-card p span,
    .reward-card-three p span {
        color: #FF4755;
        font-weight: 400;
    }

    .reward-btn-box {
        position: relative;
        top: 95px;
        padding-bottom: 100px;
    }

    .reward-table-2 tr th,
    .reward-table-2 tr td {
        line-height: 18.42px !important;
        font-size: 14px !important;
    }

    .reward-table-2 tr td.reward-table-2-footer-text {
        padding-top: 15px !important;
    }

    .join-box h3 {
        color: white;
        text-align: center;
        margin-bottom: 40px;
        font-size: 32px;
        font-weight: 600;
        line-height: 42.56px;
    }

    .join-box p {
        color: white;
        font-size: 16px;
        font-weight: 400;
        line-height: 21.28px;
        text-align: center;
        margin-bottom: 30px;
    }

    .join-box {
        padding: 70px 32px !important;
    }

}


.reward-table {
    background: white;
    width: 40%;
    border-radius: 12px;
    margin: 0 auto;
    box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 28%) !important;
    text-align: center;
}

.reward-table tr th {
    font-size: 20px;
}

.reward-table tr td {
    font-size: 18px;
}

.reward-table tr th,
.reward-table tr td {
    padding: 15px;
}

.reward-table thead {
    background: #ff4755;
    color: white;
    box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 28%) !important;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.reward-table thead tr th:first-child {
    border-top-left-radius: 12px !important;
}

.reward-table thead tr th:nth-child(2) {
    border-top-right-radius: 12px !important;
}

.reward-table-2 {
    background: white;
    width: 90%;
    margin: 0 auto;
    box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 28%) !important;
    border-radius: 12px;
    margin-top: 45px !important;
}

.reward-table-2 tr th,
.reward-table-2 tr td {
    padding: 15px;
}

.reward-table-2 tr th {
    font-size: 20px;
    color: #555556;
    font-weight: 600;
    line-height: 38.57px;
}

.reward-table-2 tr td {
    font-size: 18px;
    border-bottom: 1px solid #ddd;
    color: #737376;
    font-weight: 400;
}

.new-img {
    width: 100%;
}

.new-r-btn {
    background: #FF4755;
    width: 301px;
    height: 73px;
    border-radius: 84px;
    font-weight: 700;
    font-size: 21px;
}

.term-col-1 {
    color: #555556;
    font-size: 16px;
}

.term-col {
    color: #CFD3D5;
    font-size: 16px;
}

.fs-69 {
    font-size: 69px;
}

.fs-60 {
    font-size: 60px;
}

.fs-55 {
    font-size: 55px;
}

.fs-34-light {
    font-size: 34px;
    font-weight: 400;
    color: #CFD3D5;
}

.m-mt-85 {
    margin-top: -85px;
}

.m-mt-40 {
    margin-top: -40px;
}

.reward-table-2 tr td.reward-table-2-footer-text {
    color: #737376;
    padding-top: 100px;
}

.color-03 {
    color: #A8A8AD;
}

.opn-acc-btn {
    width: 397px;
    height: 97px;
    border-radius: 20px;
    background-color: white;
    color: #FF4755 !important;
    box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 28%);
    font-size: 30px;
    font-weight: 600;
    margin-top: -50px;
}

.opn-acc-btn:hover {
    background-color: #f0f0f0 !important;
    /* A light gray shade */
}


.color-04 {
    color: #737376;
    margin-top: 50px !important;
}

.account-section.cta-section.new-section {
    background-image: url("../../assets/lines_bg.png");
    background-size: contain;
    background-repeat: no-repeat;
}

.color-05 {
    color: #555556;
    margin-top: 20px;
}

@media screen and (max-width: 576px) {
    .opn-acc-btn {
        margin-top: -24px !important;
        width: 266px;
        height: 56px;
        font-size: 16px;
        border-radius: 8px;
    }

    .reward-table {
        width: 80%;
    }

    .fs-55 {
        font-size: 30px;
    }

    .new-r-btn {
        width: 227px;
        height: 52px;
        font-size: 16px !important;
        padding: 0 32px;
    }
}