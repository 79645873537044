.cta-section ul li{
    list-style: disc;
}
.cta-section {
    background: #fff;
}
.cta-section .row.ctaBGFade:nth-child(1):before {
    content: '';
    background: #EBA900;
    position: absolute;
    top: 0;
    inset-inline-end: 0;
    width: 822px;
    height: 822px;
    border: 822px;
    opacity: 0.1;
    filter: blur(170px);
    -webkit-backdrop-filter: blur(170px);
    backdrop-filter: 170px;
    animation: 1.2s ease-in-out ctaBgAnimate;
    -webkit-animation: 1.2s ease-in-out ctaBgAnimate;
    -moz-animation: 1.2s ease-in-out ctaBgAnimate;
}
.cta-section .row.ctaBGFade:nth-child(2):before {
    content: '';
    background: #5F00D9;
    position: absolute;
    top: 0;
    inset-inline-start: 0;
    width: 822px;
    height: 822px;
    border: 822px;
    opacity: 0.1;
    filter: blur(170px);
    -webkit-backdrop-filter: blur(170px);
    backdrop-filter: 170px;
    animation: 1.2s ease-in-out ctaBgAnimate;
    -webkit-animation: 1.2s ease-in-out ctaBgAnimate;
    -moz-animation: 1.2s ease-in-out ctaBgAnimate;
}
.cta-section .row.ctaBGFade:nth-child(3):before {
    content: '';
    background: #008BD9;
    position: absolute;
    top: 0;
    inset-inline-end: 0;
    width: 822px;
    height: 822px;
    border: 822px;
    opacity: 0.1;
    filter: blur(170px);
    -webkit-backdrop-filter: blur(170px);
    backdrop-filter: 170px;
    animation: 1.2s ease-in-out ctaBgAnimate;
    -webkit-animation: 1.2s ease-in-out ctaBgAnimate;
    -moz-animation: 1.2s ease-in-out ctaBgAnimate;
}
#lottie1, #lottie2, #lottie3, #lottie4{
    height: 746px;
    /* width: 746px; */
}

/* NEW ADDED */
.cta-section > div.container > .row > div > div > div > * {
    margin-bottom: 32px;
}
/* NEW ADDED */
@media screen and (max-width: 576px) {
    .cta-section .row:nth-child(2):before{width: 100% !important;}
    #lottie1, #lottie2, #lottie3, #lottie4 {
        height: auto;
    }
}