/* BREADCRUM STARTS */
.company-profile-breadcrum-section{
    background-color: #fff;
}
.company-profile-breadcrum-section .content {
    width: 589px;
    margin-bottom: 78px;
}
.company-profile-breadcrum-section .content h1 {
    margin-bottom: 32px;
}
.company-profile-breadcrum-section .center-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin-top: 70px;
}
.company-profile-breadcrum-section .users-count {
    background: #CDE3FA;
    width: 295px;
    height: 135px;
    border-radius: 20px;
    box-shadow: 0px 24.023px 80.078px 0px rgba(160, 181, 192, 0.30);
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.company-profile-breadcrum-section .experience-card {
    width: 260px;
    height: 332px;
    background: #FF4755;
    border-radius: 20px;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
}
.company-profile-breadcrum-section .left-content {
    position: absolute;
    top: 25%;
    inset-inline-start: -20px;
}
.company-profile-breadcrum-section svg#left-svg {
    position: absolute;
    top: -100px;
}
body[dir="rtl"] .company-profile-breadcrum-section svg#left-svg{
    transform: scale(-1);
}
.company-profile-breadcrum-section .right-content {
    position: absolute;
    top: 44%;
    inset-inline-end: 100px;
    /* width: 263.366px;
    height: 304px;
    border-radius: 18.059px;
    box-shadow: 30.099px 0px 90.297px 0px rgba(3, 38, 78, 0.15);
    background-color: #fff; */
}
.company-profile-breadcrum-section .professional-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* height: 100%; */
    width: 263.366px;
    height: 304px;
    border-radius: 18.059px;
    box-shadow: 30.099px 0px 90.297px 0px rgba(3, 38, 78, 0.15);
    background-color: #fff;
    position: relative;
    z-index: 1;
}
.company-profile-breadcrum-section svg#right-svg {
    position: absolute;
    top: -40px;
    inset-inline-end: -100px;
}
body[dir="rtl"] .company-profile-breadcrum-section svg#right-svg {
    transform: scale(-1);
}
/* BREADCRUM ENDS */


/* MISSION STARTS */
.mission-vision-section{
    overflow: hidden;
}
.vision svg#bg-vision{
    position: absolute;
    top: 35%;
    inset-inline-start: 12%;
}
div#lottie6 svg {
    width: 60% !important;
}

div#lottie6 {
    text-align: center;
}
/* MISSION ENDS */


/* LEADERSHIP STARTS */
.leadership-section{
    background-color: #fff;
}
.leadership-section .about-founder-section{
    padding: 0;
}
.leadership-section .heading{
    display: flex;
    justify-content: center;
    margin-bottom: 80px;
}
.leadership-section .heading h5{
    width: 1090px;
}
.leadership-section .leadership-content{
    display: flex;
    justify-content: center;
    margin-top: 80px;
}
.leadership-section .leadership-content .content{
    width: 1090px;
}
.leadership-section .leadership-content h6:first-child{
    margin-bottom: 24px;
}
/* LEADERSHIP ENDS */


/* ACCOUNT TYPE STARTS */
.section.company-account-type {
    background: #fff;
}
.section.company-account-type .card {
    padding: 32px 24px;
}
.section.company-account-type .row:nth-child(2) div.col-md-4:nth-child(2) .card {
    background: #FF4755;
    color: #fff;
}
.section.company-account-type .row:nth-child(2) div.col-md-4:nth-child(2) .card svg path {
    stroke: white;
}
.section.company-account-type .row:nth-child(2) div.col-md-4:nth-child(2) .card ul li{
    color: #fff;
}
.section.company-account-type .card ul {
    padding-left: 0;
}
.section.company-account-type .card ul li {
    display: flex;
    gap: 12px;
    /* align-items: end; */
    align-items: flex-end;
    margin-bottom: 16px;
}
.section.company-account-type .card .account-type-btns {
    display: flex;
    gap: 10px;
}
.section.company-account-type .card .account-type-btns button {
    padding: 16px 14px;
    margin: 0 !important;
}
.section.company-account-type .card .account-type-btns button.theme-light-btn {
    background: transparent;
    color: #D90700;
    border-color: #D90700;
}
.dark-x{
	color: black !important;
}
.section.company-account-type .card .account-type-btns button.theme-light-btn:hover {
    background: lightgray;
    color: black !important;
}

.section.company-account-type .row:nth-child(2) div.col-md-4:nth-child(2) .card .account-type-btns button.theme-red-btn{
    color: #000 !important;
    background-color: #fff;
}
.section.company-account-type .row:nth-child(2) div.col-md-4:nth-child(2) .card .account-type-btns button.theme-red-btn:hover{
    background: lightgray;
    color: black !important;
}
.section.company-account-type .row:nth-child(2) div.col-md-4:nth-child(2) .card .account-type-btns button.theme-light-btn{
    color: #fff;
    border-color: #fff;
}
.section.company-account-type .card h5 {
    margin-bottom: 24px;
}
/* ACCOUNT TYPE ENDS */


.section.company-features-section .row {
    justify-content: center;
}
.company-features-section .card {
    padding: 40px;
    border: 0;
}
.company-features-section div:nth-child(1) .card{
    width: 533px;
    height: 522px;
}
.company-features-section div:nth-child(2) .card{
    width: 533px;
    height: 739px;
}
.company-features-section div:nth-child(3) .card{
    width: 533px;
    height: 502px;
    margin-top: -190px;
}
.company-features-section div:nth-child(4) .card{
    width: 533px;
    height: 285px;
}
.company-features-section .card h6 {
    margin-bottom: 24px;
}
.section.company-features-section .row > div {
    width: auto;
}

/* .company-features-section .card img{
    position: absolute;
    left: 50%;
    right: 50%;
    transform: translate(-50%);
    bottom: 10%;
} */
.company-features-section div:nth-child(4) .card .card-body{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.section.company-features-section .card-body {
    display: flex;
    flex-direction: column;
    z-index: 1;
}
.company-features-section .card img {
    width: fit-content;
    margin-top: auto;
    margin-right: auto;
    margin-left: auto;
}
.section.company-features-section > .container > .row.ctaBGFade > div:first-child > .card:after {
    content: url('/src/assets/svg/go-supreme-bg.svg');
    position: absolute;
    bottom: -200px;
    left: 50%;
    transform: translate(-50%);
    animation: 1s ease-in-out fadeInBG;
    -webkit-animation: 1s ease-in-out fadeInBG;
}
.company-features-section .row.ctaBGFade div:nth-child(2) .card:before {
    content: '';
    border-radius: 373px;
    background: #C8E2FF;
    filter: blur(177.4644012451172px);
    -webkit-backdrop-filter: blur(177.4644012451172px);
    backdrop-filter: blur(177.4644012451172px);
    position: absolute;
    width: 373px;
    height: 373px;
    bottom: 0;
    animation: 1s ease-in-out fadeInBG;
    -webkit-animation: 1s ease-in-out fadeInBG;
}
.company-features-section .card {
    overflow: hidden;
}
.company-features-section .row.ctaBGFade div:nth-child(3) .card:before {
    content: '';
    border-radius: 272px;
    opacity: 0.3;
    background: #C89CFF;
    filter: blur(110.91525268554688px);
    -webkit-backdrop-filter: blur(110.91525268554688px);
    backdrop-filter: blur(110.91525268554688px);
    position: absolute;
    width: 272px;
    height: 272px;
    bottom: 0;
    inset-inline-start: 0;
    animation: 1s ease-in-out fadeInBG3;
    -webkit-animation: 1s ease-in-out fadeInBG3;
}
.vision .row > div {
    z-index: 1;
}
.vision div#lottie7 {
    width: 80%;
    position: relative;
    inset-inline-start: 10%;
}

@keyframes fadeInBG {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes fadeInBG3 {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 0.3;
    }
}
@media screen and (max-width: 576px) {
    .company-features-section div .card{
        /* width: 335px !important; */
        width: 100% !important;
        padding: 24px 16px;
    }
    .company-features-section div .card .card-body{
        padding: 0;
    }
    .company-profile-breadcrum-section{
        height: 100vh;
        overflow: hidden;
    }
    .company-profile-breadcrum-section .content{
        width: unset !important;
    }
    .company-profile-breadcrum-section .center-content{
        margin-top: 0 !important;
    }
    .company-profile-breadcrum-section .experience-card{
        width: 104px !important;
        height: 160px !important;
    }
    .company-profile-breadcrum-section .experience-card p{
        font-size: 12px;
        text-align: center;
    }
    .company-profile-breadcrum-section .experience-card svg{
        width: 61px;
        height: 61px;
    }
    .company-profile-breadcrum-section svg#left-svg{
        width: 150px;
        height: 150px;
        left: -50px;
    }
    .company-profile-breadcrum-section .right-content{
        width: 150px !important;
        height: 182px !important;
        top: 30% !important;
        right: 15px !important;
    }
    .company-profile-breadcrum-section svg#right-svg{
        width: 220px;
        height: 220px;
        top: 70px !important;
    }
    .company-profile-breadcrum-section .users-count{
        width: 111px !important;
        height: 68px !important;
        margin-top: 60%;
    }
    .company-profile-breadcrum-section .professional-card{
        align-items: unset !important;
        text-align: center;
        padding: 10px;
        width: 150px;
        height: 182px;
    }
    .company-profile-breadcrum-section .professional-card span{
        font-size: 10px;
    }
    .section.company-features-section .row > div{
        width: 100%;
    }
    .section.company-features-section .row > div:not(:last-child) .card {
        margin-bottom: 24px;
    }
    .section.company-features-section .card.last-card {
        height: 168px !important;
    }
    .company-features-section div:nth-child(3) .card {
        margin-top: unset;
    }
}
