.account-section .account-content .heading{
    margin-bottom: 24px;
}
.account-section .account-content{
    margin-bottom: 48px;
}
.account-section .account-points .items:not(:last-child){
    margin-bottom: 48px;
}
.account-section .account-points .items .icon{
    margin-bottom: 16px;
}
.account-section .account-points .items .item-heading{
    margin-bottom: 8px;
}
.account-section .account-points .items .icon img{
    width: 48px;
}