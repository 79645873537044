.section.market-news-section {
    overflow: hidden;
}
/* .section.market-news-section .market-news-breadcrum { */
.section.market-news-section {
    /* height: 858px; */
    background-image: url('/src/assets/LooperGroup.png');
    background-size: cover;
    background-position: center;
    /* background-color: #fff; */
    background-position: 183% -720px;
    background-repeat: no-repeat;
}
.section.market-news-section .market-news-breadcrum .content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin-top: 70px;
    margin-bottom: 258px;
}
.section.market-news-section .news-list .card {
    border-radius: 24px;
    border: 0;
    background: #F2F7FB;
    width: 422px;
    /* height: 331px; */
    padding: 0;
    transition: 0.2s;
    cursor: pointer;
}
.section.market-news-section .news-list .card-body {
    padding: 32px;
}
.news-meta {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 24px 0;
}
.author {
    display: flex;
    align-items: center;
    gap: 16px;
}
.section.market-news-section .news-list .card:hover {
    background: #fff;
    box-shadow: 40px 0px 120px 0px rgba(3, 38, 78, 0.15);
}
.section.market-news-section .news-list .card-body img{
    width: 56px;
}
.market-news-section .live-stock-section{
    margin-bottom: 120px;
}

@media screen and (max-width: 576px) {
    .section.market-news-section .market-news-breadcrum .content{
        margin-bottom: 166px;
    }
    .row.news-list{
        justify-content: center;
    }
    .section.market-news-section .news-list .card{
        width: 335px;
    }
    .market-news-breadcrum h1 {
        font-size: 32px;
        margin-bottom: 16px;
    }
    .section.market-news-section .news-list .card-body svg {
        width: 100%;
    }
}