.section.team-section {
    padding-top: 0;
}
.section.team-section .container{
    padding-left: 0;
    padding-right: 0;
}
.team-section .card {
    border: 0;
    /* height: 438px; */
    position: relative;
    overflow: hidden;
    background: transparent;
    height: 355px;
}
.team-section .card-body {
    padding: 0;
    text-align: center;
}
.team-section div.image {
    display: inline-block;
    background: #F0F7FF;
    border-radius: 50%;
    /* width: 300px;
    height: 300px; */
    margin-top: 50px;
    position: relative;
    /* margin-bottom: 24px; */
    height: 243px;
    width: 243px;
}
.team-section div.image > img {
    display: inline-block;
    /* width: 300px;
    height: 350px; */
    margin-top: -50px;
    border-radius: 0 0 150px 150px;
    width: 243px;
    height: 293px;
}
.team-section .content > * {
    margin-bottom: 8px;
}
.team-section .content {
    position: absolute;
    bottom: -110px;
    width: 100%;
    backdrop-filter: blur(15px);
    border-radius: 15px;
    transition: bottom 0.5s;
    height: 150px;
}

.team-section .card:hover .content {
    bottom: 0;
    transition-delay: 0s;
    height: auto;
}
.team-section .heading{
    margin-bottom: 100px;
}
.section.team-section .row:nth-child(1) {
    justify-content: center;
}
.team-para {
    position: relative;
    width: 1090px;
    height: 168px;
    color: #4d535f;
    /* margin-bottom: 150px; */
    /* margin-top: 84px; */
    padding: 0;
    display: flex;
    gap: 109px;
}
.team-para h6 {
    /* position: absolute; */
    top: 0px;
    left: 109px;
    display: inline-block;
    width: 853px;
}
/* .team-para img {
    position: absolute;
    top: 1px;
    left: -3px;
    width: 6px;
    height: 170px;
} */
.section.team-section .row:nth-child(2){
    position: relative;
}
.section.team-section .row:nth-child(2):before {
    content: '';
    position: absolute;
    width: 1507.538px;
    height: 598.481px;
    flex-shrink: 0;
    background: linear-gradient(266deg, #FFF09B 8.16%, #F00 91.43%);
    opacity: 0.2;
    filter: blur(150px);
    top: -250px;
    left: -850px;
}
.custom-flex {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 24px;
    justify-content: space-around;
}


@media screen and (max-width: 576px) {
    .section.team-section .container {
        padding-right: calc(var(--bs-gutter-x) * .5);
        padding-left: calc(var(--bs-gutter-x) * .5);
    }
    .team-para {
        width: 100%;
        padding: 0 20px;
        gap: 10px;
    }
    .team-para h6 {
        width: 100%;
    }    
}