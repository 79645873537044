@keyframes floatEthereum {
    0% {
        top:70%;
        /* left:50%; */
        inset-inline-start:50%;
		opacity:0;
		transform: scale(0.5);
		/* transform: translate(-50%, 0); */
    }
    100% {
        top:50%;
        /* left:20%; */
        inset-inline-start:20%;
		opacity:1;
    }
}
@keyframes floatBitcoin {
    0% {
        top:70%;
        /* left:50%; */
        inset-inline-start:50%;
		opacity:0;
		transform: scale(0.5);
    }
    100% {
        top:37%;
        /* left:7%; */
        inset-inline-start:7%;
		opacity:1;
    }
}
@keyframes floatDollar {
    0% {
        top:70%;
        /* left:50%; */
        inset-inline-start:50%;
		opacity:0;
		transform: scale(0.5);
    }
    100% {
        top:15%;
        /* left:0; */
        inset-inline-start:0;
		opacity:1;
    }
}
@keyframes floatPound {
    0% {
        top:70%;
        /* left:50%; */
        inset-inline-start:50%;
		opacity:0;
		transform: scale(0.5);
    }
    100% {
        bottom:10%;
        /* left:6%; */
        inset-inline-start:6%;
		opacity:1;
    }
}
@keyframes floatTesla {
    0% {
        top:70%;
        /* right:50%; */
        inset-inline-end:50%;
		opacity:0;
		transform: scale(0.5);
    }
    100% {
        bottom:10%;
        /* right:22%; */
        inset-inline-end:22%;
		opacity:1;
    }
}
@keyframes floatEuro {
    0% {
        top:70%;
        /* right:50%; */
        inset-inline-end:50%;
		opacity:0;
		transform: scale(0.5);
    }
    100% {
        bottom:10%;
        /* right:7%; */
        inset-inline-end:7%;
		opacity:1;
    }
}
@keyframes floatGoogle {
    0% {
        top:70%;
        /* right:50%; */
        inset-inline-end:50%;
		opacity:0;
		transform: scale(0.5);
    }
    100% {
        bottom:10%;
        /* right:0%; */
        inset-inline-end:0%;
		opacity:1;
    }
}
@keyframes floatApple {
    0% {
        top:70%;
        /* right:50%; */
        inset-inline-end:50%;
		opacity:0;
		transform: scale(0.5);
    }
    100% {
        bottom:10%;
        /* right:6%; */
        inset-inline-end:6%;
		opacity:1;
    }
}
@keyframes float {
	0% {
		transform: translatey(0px);
	}
	50% {
		transform: translatey(-20px);
	}
	100% {
		transform: translatey(0px);
	}
}