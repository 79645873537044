.features-list{
    margin: 32px 0;
}
.features-list ul li {
    margin-bottom: 20px;
}
.features-list ul li img {
    margin-inline-end: 5px;
}
.section.features-section .footer-logo img {
    transform: translateY(120%);
    opacity: 0;
    transition: 1.5s ease all;
}
.section.features-section .footer-logo img.show {
    transform: translateY(0%);
    opacity: 1;
}
.section.features-section .footer-logo {
    overflow: hidden;
}
@media screen and (max-width: 576px) {
    .features-section .container {
        padding: 30px 30px 0 !important;
    }
    .features-section .col-lg-6:nth-child(2){
        padding: calc(var(--bs-gutter-x) * .5) !important;
    }
    .section.features-section .row .footer-logo {
        text-align: center;
    }
    .section.features-section .feature-btn {
        text-align: center;
        margin-bottom: 5rem;
    }
}