.section.investment-section .card{
    padding: 60px 40px;
    height: 703px;
    border-radius: 32px;
    border: 0;
}
.section.investment-section .main-img{transition: transform 0.5s;}
.section.investment-section .card:hover .main-img {
    transform: scale(1.2);
}

body[dir="rtl"] img.arrow-img {
    transform: scaleX(-1);
}

.section.investment-section .card-img {
    display: flex;
    flex-direction: column;
    /* align-items: self-end; */
    position: absolute;
    inset-inline-end: -100px;
    top: 50%;
    align-items: center;
    gap: 24px;
}