.contact-content {
    margin-bottom: 48px;
}
.contact-content h5 {
    margin-bottom: 24px;
}
.contact-content p{
    margin-bottom: 16px;
}
.contact-email > * {
    display: inline-block;
}
.contact-phone > * {
    display: inline-block;
}
.contact-address > * {
    display: inline-block;
}
.contact-address {
    display: flex;
    margin-bottom: 24px;
}
.contact-email img, .contact-phone img, .contact-address img{
    margin-right: 16px;
    width: 20px;
}
.contact-social img {
    margin-right: 32px;
}
.contact-form {
    padding: 32px 24px;
    background: #fff;
    border-radius: 32px;
}
.contact-form .form-group {
    margin-bottom: 16px;
}
.contact-form .form-group label.form-label {
    font-size: 20px;
    font-weight: 400;
}
.contact-form input, .contact-form textarea, .contact-form select {
    border-radius: 16px;
    padding: 16px;
    border: 1px solid #CED2D4;
}
.contact-details div:nth-child(1), .contact-details div:nth-child(2) {
    margin-bottom: 20px;
}
.contact-details div:nth-child(1) p, .contact-details div:nth-child(2) p {
    margin-bottom: 0px;
}
.section.contact-section{
    overflow: hidden;
}
.section.contact-section:after {
    content: '';
    position: absolute;
    width: 1556.237px;
    height: 663.551px;
    background: linear-gradient(266deg, #FFF09B 8.16%, #F00 91.43%);
    opacity: 0.2;
    filter: blur(150px);
}
.contact-section .contact-us {
    width: 557px;
}
#contactFormSubmit:disabled{
    background-color: #D90700;
}

@media screen and (max-width: 576px) {
    .contact-section .contact-us{
        width: unset;
    }
}