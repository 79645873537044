.live-stock .tape-N_kypjt9{
	animation-play-state: running !important;
}
.live-stock .tape-N_kypjt9:hover{
	animation-play-state: running !important;
}
.live-stock .new-div{
	
}
.live-stock .new-div:nth-child(2) {
	margin-top : 10px;
	margin-bottom : 20px;
    border: 1px solid #ddd;
}
.live-stock .new-div .tradingview-widget-container .tradingview-widget-container__widget:nth-child(2) {
    display : none;
}
.live-stock .new-div .tradingview-widget-container .tradingview-widget-container__widget div:nth-child(2){
    display : none;
}
@media (any-hover:hover){.tape-N_kypjt9:hover{animation-play-state:running !important;}}

.section.live-stock-section {
    background: #fff;
    overflow: hidden;
}
.live-stock-section .row {
    white-space: nowrap;
}
.live-stock-section .card {
    /* width: 310px; */
    width: fit-content;
    height: 80px;
    border-radius: 16px;
    border: 0px;
    box-shadow: 2px 2px 8px #00000012;
    display: inline-block;
    margin-right: 18px;
}
.live-stock-section .card-body {
    padding: 12px;
}
.left-content {
    float: inline-start;
}
.right-content {
    float: inline-end;
    margin-inline-start: 8px;
}
.upper-content > * {
    display: inline-block;
    margin: 0;
}
.lower-content > * {
    display: inline-block;
    margin: 0;
}

.live-stock-section .cards-animations{
    animation: 15s linear infinite backwards swipe;
    -webkit-animation: 15s linear infinite backwards swipe;
    -moz-animation: 15s linear infinite backwards swipe;
}
.live-stock-section .forex-cards-animations{
    animation: 7s linear infinite backwards swipeForex;
    -webkit-animation: 7s linear infinite backwards swipeForex;
    -moz-animation: 7s linear infinite backwards swipeForex;
}
.live-stock-section .left-content .upper-content img {
    width: 15px;
    margin-inline-end: 5px;
}
.forex-cards-animations .card .left-content .upper-content img {
    display: none;
}
.fcs-brand{display:none !important;}

body[dir='rtl']  .live-stock-section .cards-animations{
    animation: 15s linear infinite backwards swipeRTL;
    -webkit-animation: 15s linear infinite backwards swipeRTL;
    -moz-animation: 15s linear infinite backwards swipeRTL;
}
body[dir='rtl'] .live-stock-section .forex-cards-animations{
    animation: 7s linear infinite backwards swipeForexRTL;
    -webkit-animation: 7s linear infinite backwards swipeForexRTL;
    -moz-animation: 7s linear infinite backwards swipeForexRTL;
}

@keyframes swipeForex {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-15.5%);
    }
}
@keyframes swipeForexRTL {
    0% {
        transform: translateX(0%);
    }

    100% {
        transform: translateX(15.5%);
    }
}

@keyframes swipe {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-50%);
    }
}
@keyframes swipeRTL {
    0% {
        transform: translateX(0%);
    }

    100% {
        transform: translateX(50%);
    }
}

@-webkit-keyframes swipe {
    0% {
        transform: translate(0);
    }

    100% {
        transform: translate(-100%);
    }
}
@-webkit-keyframes swipeRTL {
    0% {
        transform: translateX(0%);
    }

    100% {
        transform: translateX(50%);
    }
}

.forex-card{
	border:1px solid #ddd;
}
.forex-card .title{
	font-size: 16px;
    font-weight: 600;
    display: block;
}
.forex-card .title-right{
	font-size: 15px;
    font-weight: 400;
    float: right;
}
.forex-card div{
	font-size: 15px;
    font-weight: 400;
}
.forex-card .subtitle{
	font-size: 12px;
    font-weight: 400;
    text-align: left;
    color: #8d8d8d;
}
.forex-card label{
	font-size: 10px;
    font-weight: 400;
    text-align: left;
    color: #8d8d8d;
}
.marquee {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  box-sizing: border-box;
  margin-bottom: 10px;
}

.marquee-content {
  display: inline-block;
  padding-left: 100%;
  animation: marquee 60s linear infinite;
}

@keyframes marquee {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}

.forex-card {
    display: inline-block;
    margin-right: 0rem;
    padding: 2px 5px;
    border: 1px solid #ccc;
    background: white;
    width: 200px;
}
.forex-card .positive{
	color : green ;
}
.forex-card .negative{
	color : red ;
}