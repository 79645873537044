.section.about-commitment {
    padding: 0;
}
.section.about-commitment .row > .col-md-5 > div > div {
    border-left: 2px solid #ECEFF1;
    padding-left: 80px;
    position: relative;
}
.section.about-commitment .row > .col-md-5 > div > div:before {
    content: '';
    position: absolute;
    width: 20px;
    height: 80px;
    background-color: #4D535F;
    left: -10px;
    top: 50px;
    border-radius: 24px;
}

@media screen and (max-width: 576px) {
    .section.about-commitment .row > .col-md-5 > div > div{
        border-left: unset;
        padding-left: unset;
    }
    .section.about-commitment .row > .col-md-5 > div > div:before{
        content: none;
    }
}