.section.about-breadcrum-section {
    --i: 2;
    /* height: 968px; */
    height: 858px;
    background-image: url('/src/assets/svg/pattern-4.svg');
    background-size: cover;
    background-position: 50% 130%;
    background-color: #fff;
    /* animation: markersFadeIn 2s 1s both;
    animation-delay: calc(.8s * var(--i)); */
}
.section.about-breadcrum-section .content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin-top: 70px;
}
.section.about-breadcrum-section .content h1{
    margin-bottom: 32px;
}
.section.about-breadcrum-section .content > * {
    width: 480px;
}
.yellow-mark, .grey-mark, .red-mark, .blue-mark, .green-mark{
    position: absolute;
    width: auto;
    text-align: center;
}
.yellow-mark{
    /* top: 207px;
    left: 72px; */
    top: 24%;
    inset-inline-start: 5%;
}
.grey-mark{
    /* top: 527px;
    left: 351px; */
    top: 61%;
    inset-inline-start: 23%;
}
.red-mark{
    /* top: 448px;
    left: 692px; */
    top: 52%;
    inset-inline-start: 45%;
}
.blue-mark{
    /* top: 553px;
    left: 1035px; */
    top: 64%;
    inset-inline-start: 68%
}
.green-mark{
    /* top: 179px;
    left: 1237px; */
    top: 21%;
    inset-inline-start: 81.5%;
}
.markers > div {
    animation: markersFadeIn 2s 1s both;
    animation-delay: calc(.8s * var(--i));
}
@keyframes markersFadeIn {
    from { opacity:0; }
    to { opacity: 1; }
}

@media screen and (max-width: 576px) {
    .section.about-breadcrum-section{
        background-position: 25% 46%;
        height: 420px;
        background-size: unset;
    }
    .section.about-breadcrum-section .content{
        margin-top: unset;
    }
    .section.about-breadcrum-section .content h1 {
        margin-bottom: 16px;
    }
    .section.about-breadcrum-section .content p {
        font-size: 16px;
    }
    .section.about-breadcrum-section .content > *{
        width: 100%;
    }

    .section.about-founder-section .card{
        width: 100% !important;
        height: auto !important;
        padding: 30px 20px !important;
        border-radius: 0;
    }
    .founder-content{
        position: unset !important;
        width: 100% !important;
    }
    .markers > div > img {
        width: 24px;
    }
    .markers > div > p {
        font-size: 12px;
    }
    .yellow-mark {
        top: 50%;
    }
    .grey-mark {
        top: 75%;
    }
    .red-mark {
        top: 62%;
        inset-inline-start: 38%;
    }
    .blue-mark {
        top: 75%;
    }
    .green-mark {
        top: 50%;
    }
}