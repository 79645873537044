.learn-section .card{
    /* padding: 48px; */
    padding: 20px;
    border: none;
}
.learn-section .row:nth-child(2){
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
}
.learn-section .row:nth-child(2) .col-md-12:first-child .card{
    background-color: rgb(255, 71, 85);
    color: rgb(255, 255, 255);
    height: 748px;
}
.learn-section .row:nth-child(2) .col-md-12:nth-child(2) .card, 
.learn-section .row:nth-child(2) .col-md-12:nth-child(3) .card{
    height: 362px;
}
.learn-section .row:nth-child(2) .col-md-12:nth-child(2) .card .card-body div,
.learn-section .row:nth-child(2) .col-md-12:nth-child(3) .card .card-body div{
    margin: 24px 0 50px 0;   
}

.learn-section .row:nth-child(2) .col-md-12:nth-child(3) .card{
    background-color: #4D535F;
    color: rgb(255, 255, 255);
}
.section.learn-section .card img {
    transition: 1s ease;
}
.section.learn-section .card:hover img {
transform: scale(1.2);
}


/* NEW ADDED */
.learn-section > div.container > .row:nth-child(1) > div > div > h2 {
    margin-bottom: 32px;
}
.learn-section > div.container > .row:nth-child(1) > div > div > p {
    margin-bottom: 68px;
}
.learn-section .card{
    padding: 48px;
}
.learn-section .card-body{
    padding: 0px;
}
.learn-section .row:nth-child(2) .col-md-12:first-child .card{
    height: 672px;
}
.learn-section .row:nth-child(2) .col-md-12:nth-child(2) .card, 
.learn-section .row:nth-child(2) .col-md-12:nth-child(3) .card,
.learn-section .row:nth-child(2) .col-md-12:nth-child(4) .card {
    height: 324px;
}
.learn-section .row:nth-child(2) .col-md-12:nth-child(2) .card .card-body div,
.learn-section .row:nth-child(2) .col-md-12:nth-child(3) .card .card-body div{
    margin: 24px 0 32px 0;   
}
.learn-section .last-card h6{
    width: 375px;
}
/* NEW ADDED */

@media screen and (max-width: 576px) {
    .learn-section .last-card h6{
        width: unset;
    }
    .learn-section .row:nth-child(1) .col-md-12 div {width: 100% !important;}
    .learn-section .row:nth-child(2) {
        display: flex;
    }
    .learn-section .row:nth-child(2) .col-md-12:first-child .card{
        height: 432px;
        margin-bottom: 20px;
    }
    .learn-section .row:nth-child(2) .col-md-12:first-child .card img{
        display: none;
    }
    .learn-section .row:nth-child(2) .col-md-12:first-child .card h3{
        margin-bottom: 8rem;
    }
    .learn-section .row:nth-child(2) .col-md-12:nth-child(2) .card, 
    .learn-section .row:nth-child(2) .col-md-12:nth-child(3) .card{
        height: 228px;
    }
    .learn-section .row:nth-child(2) .col-md-12:nth-child(2) .card .card-body div,
    .learn-section .row:nth-child(2) .col-md-12:nth-child(3) .card .card-body div{
        margin: unset;   
    }
    .learn-section .row:nth-child(2) .col-md-12:nth-child(4) .card h5 {
        width: unset !important;
    }
    .learn-section .card{padding: 10px;}
    .learn-section .card-body{padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x)}
    .learn-section .card-body p.p2 {
        font-size: 13px;
    }
}
.theme-light-btn:hover{
	background: lightgray;
    color: black !important;
}
.theme-light-btn{
	border: 1px solid #000000 !important;
}
.disabled-botton{
	background : #eee;
	padding: 12px;
    border-radius: 16px;
	text-align: center;
    display: inline-block;
}