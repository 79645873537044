.hero-section{
    position: relative;
    height: 100vh;
    background: #F3F4F5;
}
.hero-content{
    position: absolute;
    top: 2%;
    width: 50%;
    text-align: center;
    left: 50%;
    transform: translate(-50%, 0);
}
.hero-content > * {
    margin-bottom: 32px;
}
.golden-bricks{
    position: absolute;
    bottom: -40px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 25%;
}
.left-icons img {
    width: 141px;
    height: auto;
}
.right-icons img {
    width: 141px;
    height: auto;
}
img#tesla-icons, img#google-icons {
    width: 101px;
}
img#golden-coin1 {
    position: absolute;
    top: 62%;
    left: 35%;
    width: 3%;
    height: 88px;
    animation: float 4s ease-in-out infinite;
}
img#golden-coin2 {
    position: absolute;
    top: 58%;
    left: 58%;
    width: 4%;
    height: 74px;
    animation: float 4s ease-in-out infinite;
}
img#golden-coin3 {
    position: absolute;
    bottom: 6%;
    left: 59%;
    width: 8%;
    height: 34px;
}
img#ethereum-icons {
    position: absolute;
    top: 50%;
    /* left: 20%; */
    inset-inline-start: 20%;
    animation: floatEthereum 2.5s normal ease-out, float 4s ease-in-out infinite;
}
img#bitcoin-icons {
    position: absolute;
    top: 37%;
    /* left: 7%; */
    inset-inline-start: 7%;
    animation: floatBitcoin 2.5s normal ease-out, float 4s ease-in-out infinite;
}
img#dollar-icons {
    position: absolute;
    top: 15%;
    /* left: 0; */
    inset-inline-start: 0;
    animation: floatDollar 2.5s normal ease-out, float 4s ease-in-out infinite;
}
img#pound-icons {
    position: absolute;
    bottom: 10%;
    /* left: 6%; */
    inset-inline-start: 6%;
    animation: floatPound 2.5s normal ease-out, float 4s ease-in-out infinite;
}
img#tesla-icons {
    position: absolute;
    top: 52%;
    /* right: 22%; */
    inset-inline-end: 22%;
    animation: floatTesla 2.5s normal ease-out, float 4s ease-in-out infinite;
}
img#euro-icons {
    position: absolute;
    top: 37%;
    /* right: 7%; */
    inset-inline-end: 7%;
    animation: floatEuro 2.5s normal ease-out, float 4s ease-in-out infinite;
}
img#google-icons {
    position: absolute;
    top: 15%;
    /* right: 0; */
    inset-inline-end: 0;
    animation: floatGoogle 2.5s normal ease-out, float 4s ease-in-out infinite;
}
img#apple-icons {
    position: absolute;
    bottom: 10%;
    /* right: 6%; */
    inset-inline-end: 6%;
    animation: floatApple 2.5s normal ease-out, float 4s ease-in-out infinite;
}

@media screen and (max-width: 576px) {
    .hero-content h1 {
        font-size: 32px;
    }
    img#tesla-icons, img#google-icons {
        width: 12%;
    }
    .hero-section{height: 580px; z-index: 1;}
    .hero-content{
        width: 100%;
        top: 5%;
    }
    .golden-bricks{
        width: 75%;
        transform: translate(-50%, 0%);
        transform: translate(-55%, -15%);
    }
    .left-icons img, .right-icons img {
        width: 12%;
    }
    .golds img:nth-child(2), .golds img:nth-child(3){
        display: none;
    }
    img#golden-coin3{
        width: 72px;
        /* top: 56%; */
        left: 75%;
    }
    img#dollar-icons, img#google-icons{top: 20%;}
    /* img#bitcoin-icons, img#euro-icons{top: 27%;} */
    /* img#ethereum-icons, img#tesla-icons{top: 32%;} */
    img#pound-icons, img#apple-icons{top: 56%;}
}