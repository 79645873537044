.accordion {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* margin-top: 100px; */
    margin-top: 50px;
    margin-bottom: 150px;
}
.accordion-items {
    width: 1089px;
    padding: 20px 32px;
    margin-bottom: 20px;
    border-radius: 24px;
    cursor: pointer;
    border: 1px solid #CED2D4;
    max-height: 100px;
    overflow: hidden;
    transition: 0.5s ease-in;
}
.accordion-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.accordion-toggle-btn{
    width: 56px;
    height: 56px;
    border: 0;
    border-radius: 50px;
    background-color: #F3F4F5;
    font-size: 24px;
    color: #0CB1A0;
}
.accordion-items.show{
    background-color: #F3F4F5;
    border-color: #F3F4F5;
    max-height: 500px;
    overflow-y: auto;
}
.accordion-items.show .accordion-toggle-btn{
    background-color: #fff;
    color: #FF4755;
}
.accordion-items .accordion-content {
    width: 947px;
    color: #4D535F;
    visibility: hidden;
    transition: visibility 0.5s ease-in;
}
.accordion-items.show .accordion-content {
    visibility: unset;
    white-space: pre-line;
}