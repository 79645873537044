/* .header-section nav{
    height: 110px;
} */
@media (min-width: 1200px) and (max-width: 1398px) {
    .navbar-nav .nav-item a {
        font-size: 12px;
    }
}

@media (max-width: 1200px) {
    .navbar-expand-lg .navbar-collapse {
        display: none !important;
        flex-basis: auto;
    }
}

    .navbar-expand-lg {
        flex-wrap: nowrap;
        justify-content: space-evenly !important;
    }


nav.navbar {
    background-color: #F3F4F5 !important;
    height: 110px;
    padding: 27px 64px;
    border-bottom: 1px solid #CED2D4;
}
a.navbar-brand {
    margin: 0;
    margin-inline-end: 15px;
}
a.nav-link {
    padding-right: 40px !important;
}
.header-section .accounts-link{
    text-align: end;
}
.header-section .accounts-link >:last-child {
    margin-inline-start: 20px;
}
.accounts-link a{
    text-decoration: none;
    color: #000;
    /* line-height: 40px; */
}
span.languages-btn-group span {
    border: 1px solid;
    border-radius: 100%;
    padding: 5px;
    color: #FF4755;
    cursor: pointer;
}
span.languages-btn-group span:hover {
    color: #fff;
    background: #FF4755;
    border-color: #FF4755;
}

.navbar-brand .logo-icon{
    height: 72px;
}

@media screen and (max-width: 1200px) {
    div:has(> .accounts-link) {
        display: none;
    }
    /* div:has(> div#navbarScroll) {
        display: none;
    } */
    div#navbarScroll {
        display: none;
    }
    .header-section nav{
        padding: 10px;
        height: 80px;
        border-bottom: 1px solid #CED2D4;
    }
    .header-section nav span.languages-btn-group,
    .header-section nav .accounts-link{
        display: none;
    }
    .hamburger {
        background: #FF4755;
        padding: 12px;
        border-radius: 50%;
        height: 50px;
        width: 50px;
        float: right;
    }    
    body[dir="rtl"] .hamburger {float: left;}
    .hamburger div {
        width: 25px;
        height: 2px;
        background-color: #fff;
        margin: 5px 0px;
    }



    div#closeHam div.bar1 {
        position: relative;
        transform: rotate(45deg);
        top: 8px;
    }
    .mobile-login-btn button{
        padding: 16px 24px;
    }
    .mobile-login-btn button:first-child{
        margin-bottom: 8px;
    }
    div#closeHam div.bar2 {
        transform: rotate(-45deg);
    }
    div#resp_nav_overlay span.closebtn {
        position: absolute;
        top: -10px;
        right: 0;
        font-size: 40px;
        font-weight: bold;
        line-height: 1;
        padding: 8px;
        text-decoration: none;
        font-size: 36px;
        color: #818181;
        display: block;
        transition: 0.3s;
    }
    div#resp_nav_overlay {
        height: calc(100vh - 70px);
        margin-top: 80px;
        width: 0;
        position: fixed;
        z-index: 1050;
        top: 0;
        left: 0;
        background-color: #fff;
        overflow-x: hidden;
        transition: 0.5s;
    }
    div#resp_nav_overlay .overlay-content {
        position: relative;
        top: 0;
        width: 100%;
        text-align: center;
        margin-top: 30px;
    }
    div#resp_nav_overlay ul {
        margin: 0;
        padding: 0;
    }
    div#resp_nav_overlay li {
        list-style: none;
        margin-bottom: 10px;
        text-transform: capitalize;
    }
    div#resp_nav_overlay li a {
        color: #000;
        font-size: 20px;
        font-weight: 400;
        padding-right: 0 !important;
    }
    span.languages-btn-group.d-block {
        margin-top: 50px;
        border-top: 1px solid #F3F4F5;
        padding-top: 20px;
        padding-bottom: 40px;
    }
    nav.navbar .row .col-md-7.col-6.d-flex {
        justify-content: end;
        align-items: center;
    }
}

@media screen and (min-width: 1200px) {
    div#resp_nav_overlay {
        display: none !important;
    }
    .header-section nav.navbar .container{
        margin: 0;
    }
}