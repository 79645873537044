.experience-section .card {
    border-radius: 32px;
    border: 0;
    height: 400px;
    margin-bottom: 20px;
    transform: translateY(100px);
    opacity: 0;
    transition: .7s;
}
.experience-section .card.show {
    transform: translateY(0px);
    opacity: 1;
}
.experience-section .card img{transition: 1s;}
.experience-section .card:hover img {
    transform: scale(1.2);
}
.experience-section .card .card-body {
    display: flex;
    flex-direction: column;
    padding: 30px;
}
.experience-section .card .card-img {
    height: 250px;
    display: flex;
}

@media screen and (max-width: 576px) {
    .experience-section .card {
        height: 228px;
    }
    .experience-section .card .card-body {
        padding: 20px;
        text-align: center;
    }
    .experience-section .card .card-img {
        height: 100px;
    }
    .experience-section .card .card-content > * {
        font-size: 24px;
    }
}