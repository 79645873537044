.account-type-section .card {
    padding: 30px;
    /* height: 736px; */
    border: 1px solid #E1DFDF;
    height: 630px;
}
.section.account-type-section .row:first-child {
    margin-bottom: 100px;
}
.section.account-type-section h5 {
    margin-bottom: 32px;
}
.section.account-type-section ul li > * {
    display: inline-block;
}
.section.account-type-section ul{
    margin: 0;
    padding: 0;
}
.section.account-type-section ul li {
    margin-bottom: 10px;
}
.account-type-btns {
    margin-top: auto;
}
.section.account-type-section ul li:last-child h6 {
    white-space: pre-wrap;
}
.section.account-type-section ul li:last-child img {
    vertical-align: super;
}
.section.account-type-section ul li h6 {
    margin-left: 12px;
}
.section.account-type-section:before {
    content: '';
    position: absolute;
    width: 1115.353px;
    height: 470.67px;
    flex-shrink: 0;
    background: linear-gradient(266deg, #FFF09B 8.16%, #F00 91.43%);
    opacity: 0.2;
    filter: blur(150px);
    left: 273px;
}
.section.account-type-section ul li {
    display: flex;
    gap: 12px;
    /* align-items: end; */
    align-items: flex-end;
}
.section.account-type-section .card p {
    margin-bottom: 0;
}

@media screen and (max-width: 576px) {
    .account-type-section .card{
        margin-bottom: 24px;
    }
    .section.account-type-section:before{
        width: 100%;
        left: 0;
    }
}