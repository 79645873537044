.section.about-principles {
    padding: 0;
}

.section.about-principles .row > div:first-child > div > div a > button {
    margin-top: 24px;
}


@media screen and (max-width: 576px) {
    div#lottie4 {
        height: unset;
    }
}