/* body[dir='rtl'] .slick-track {
    transform: translate3d(1296px, 0px, 0px) !important;
} */
.testimonial-section{
    background-color: #fff;
}
.testimonial-section .heading{
    margin-bottom: 100px;
}
.testimonial-section .slick-initialized .slick-slide {
    opacity: 0.4;
    transition: 0.5s ease;
}
.testimonial-section .slick-slide.slick-active.slick-current {
    width: 755px !important;
    /* margin-right: 162px; */
    margin-inline-end: 162px;
    position: relative;
    opacity: 1;
}
.testimonial-section .slick-slide.slick-active.slick-current:after {
    content: '';
    position: absolute;
    top: 0;
    /* right: -80px; */
    inset-inline-end: -80px;
    width: 1px;
    height: 509px;
    opacity: 0.4;
    border: 1px dashed #4D535F;
}
.testimonial-section .card {
    background: transparent;
    border: 0;
    border-radius: 0;
    width: 755px;
}
.testimonial-section .testimonial-text {
    color: #4D535F;
    margin-bottom: 48px;
    margin-top: 48px;
}
.testimonial-section .below-section {
    display: flex;
    align-items: center;
}
.testimonial-section .name {
    margin-inline-start: 16px;
}
.testimonial-section .slick-list{
    cursor: grab;
}
.testimonial-section .slick-list:active {
    cursor: grabbing;
}
body[dir='rtl'] .testimonial-section .quote-image img {
    transform: scaleX(-1);
}


@media screen and (max-width: 576px) {
    .testimonial-section .slick-slide.slick-active.slick-current{
        width: 351px !important;
        margin-right: unset;
        position: unset;
        height: auto;
    }
    .testimonial-section .slick-initialized .slick-slide{
        height: 0;
    }
    .testimonial-section .card{
        width: unset;
    }
    button.slick-arrow.slick-prev, button.slick-arrow.slick-next {
        display: none !important;
    }
    .testimonial-text h5 {
        font-size: 20px;
    }
}