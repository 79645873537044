.investment-section.investmentBGFade:before {
    content: '';
    position: absolute;
    top: 20%;
    width: 100%;
    height: 514.821px;
    flex-shrink: 0;
    background: linear-gradient(266deg, #FFF09B 8.16%, #F00 91.43%);
    opacity: 0.2;
    filter: blur(150px);
    -webkit-backdrop-filter: blur(150px);
    backdrop-filter: blur(150px);
    animation: 1.2s ease-in-out investmentBGAnimate;
    -webkit-animation: 1.2s ease-in-out investmentBGAnimate;
}
/* New Added */
.section.investment-section .row div:nth-child(3) .card {
    background: url('/src/assets/noise.png'), lightgray 0% 0% / 59.400004148483276px 59.400004148483276px repeat;
    background-repeat: no-repeat;
    background-position: center;
}
.section.investment-section img.main-img {
    width: 40% !important;
}
.section.investment-section .card{
    height: 650px;
    width: 513px;
}
.investment-section > div.container > .row:nth-child(2) {
    justify-content: center;
}
.investment-section > div.container > .row:nth-child(2) > div {
    width: auto;
}
.investment-section > div.container > .row:nth-child(1) > div > div > h2 {
    margin-bottom: 32px;
}
.investment-section > div.container > .row:nth-child(1) > div > div > p {
    margin-bottom: 68px;
}

.investment-section .heading{
    /* margin-left: 120px; */
    margin-inline-start: 120px;
}
/* New Added */
@media screen and (max-width: 576px) {
    .investment-section .heading{
        /* margin-left: unset; */
        margin-inline-start: unset;
    }
    .section.investment-section .card{
        height: 432px;
        padding: 20px;
        width: 335px;
    }
    .section.investment-section .main-img {
        inset-inline-end: 15% !important;
        width: 55% !important;
        top: 48% !important;
    }

    .section.investment-section .row:nth-child(1) .col-md-12 div {
        width: 100% !important;
    }
    .investment-section:before{top: 0;}
    .section.investment-section .row:nth-child(2) div:nth-child(3) h4 {
        margin-top: 0 !important;
    }
    .section.investment-section .card-img {
        inset-inline-end: 0px !important;
    }
    .section.investment-section img.main-img {
        position: relative;
        inset-inline-end: -40px !important;
    }
    .section.investment-section .card-img svg {
        width: 50%;
        position: relative;
        inset-inline-end: -40px !important;
    }
    .section.investment-section .card-body h5 {
        width: 225px !important;
        margin-top: 24px !important;
    }
}

@keyframes investmentBGAnimate {
    0% {
        top:70%;
    }
    100% {
        top:20%;
    }
}
@-webkit-keyframes investmentBGAnimate {
    0% {
        top:70%;
    }
    100% {
        top:20%;
    }
}