.section.about-founder-section{
    background-color: #fff;
}
.section.about-founder-section .outer-row{
    justify-content: center;
}
.section.about-founder-section .card{
    /* height: 540px; */
    width: 1090px;
    background-color: #F3F4F5;
    border: 0;
    padding: 10px 122px 10px 80px;
    height: 459px;
}
.section.about-founder-section .card-body{
    padding: 0;
}
.about-founder-section div.founder-img {
    display: inline-block;
    background: #D3E8FF;
    border-radius: 50%;
    /* width: 360px;
    height: 360px; */
    margin-top: 82px;
    position: relative;
    margin-bottom: 24px;
    width: 322px;
    height: 322px;
}
.about-founder-section div.founder-img > img {
    display: inline-block;
    /* width: 298px;
    height: 442px; */
    width: 266px;
    height: 404px;
    margin-top: -72px;
    border-radius: 0 0 180px 180px;
    position: relative;
    inset-inline-start: 30px;
}
body[dir='rtl'] .about-founder-section div.founder-img > img{
    transform: scaleX(-1);
}

.about-founder-section div.founder-img:before{
    content: '';
    position: absolute;
    bottom: -27px;
    left: 40%;
    background-image: url('/src/assets/svg/quote-symbol-1.svg');
    width: 64px;
    height: 44px;
    z-index: 1;
}
.founder-name-color{
    color: #D90700;
}
.founder-content{
    width: 392px;
    position: absolute;
    top: 99px;
    inset-inline-start: 586px;
}
.founder-words{
    margin-bottom: 48px;
}
.founder-name{
    margin-bottom: 13px;
}


@media screen and (max-width: 576px) {
    .section.about-founder-section{
        padding-top: 0;
    }
}