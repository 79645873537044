.section.core-values-section .heading{
    margin-bottom: 100px;
}
.section.core-values-section .card {
    background: transparent;
    border: 0;
    display: flex;
    gap: 48px;
    width: 310px;
}
.section.core-values-section .title-image {
    background-color: #ECEFF1;
    border-radius: 24px;
    width: 310px;
    height: 367px;
    overflow: hidden;
    padding: 20px;
    position: relative;
}
.section.core-values-section .row div:nth-child(1) .card div.title-image{
    background-image: url('/src/assets/integrity-bg.png');
}
.section.core-values-section .title-image img{
    position: absolute;
    bottom: 0;
}
.section.core-values-section .row div:nth-child(3) .card div.title-image img{
    inset-inline-start: 50%;
    inset-inline-end: 50%;
    transform: translate(-50%);
}
body[dir='rtl'] .section.core-values-section .row div:nth-child(3) .card div.title-image img{
    transform: translate(50%);
}
.section.core-values-section .row div:nth-child(2) .card {
    margin-top: 88px;
}
.section.core-values-section .row div:nth-child(1) .card .title-image img {
    width: 85%;
}
.section.core-values-section .row div:nth-child(2) .card .title-image img {
    width: 100%;
}
.section.core-values-section .row div:nth-child(3) .card .title-image img {
    width: 290px;
}
.section.core-values-section .row div:nth-child(3) .card {
    margin-top: 178px;
}


@media screen and (max-width: 576px) {
    .section.core-values-section .heading{
        margin-bottom: 24px;
    }
    .section.core-values-section .card {
        gap: 24px;
        width: 100%;
    }
    .section.core-values-section .title-image {
        width: 100%;
        background-repeat: round;
    }
    .section.core-values-section .title-image img {
        left: 50%;
        transform: translateX(-50%);
    }
    .section.core-values-section .row :not(div:nth-child(1)) .card {
        margin-top: 32px;
    }
    .section.core-values-section .row div:nth-child(2) .card div.title-image img{
        right: 0;
        left: unset;
        transform: unset;
    }
    .section.company-account-type .card .account-type-btns {
        flex-direction: column;
    }
    .section.company-account-type .row:nth-child(2) div:not(:last-child) .card{
        margin-bottom: 32px;
    }
}