body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}
div#header-section {
  position: sticky;
  z-index: 999;
  top: 0;
}
.dropdown:hover>.dropdown-menu {
  display: block;
}
.dropdown>.dropdown-toggle:active {
    pointer-events: none;
}
.section.footer-section {
  background: #fff;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
h1,h2,h3,h4,h5,h6, p{
  margin-bottom: 0;
}
h1{
  /* font-size: 100px; */
  font-size: 82px;
  letter-spacing: 2px;
}
h2{
  font-size: 64px;
  letter-spacing: -1px;
}
h3{
  font-size: 56px;
  letter-spacing: -1px;
}
h4{
  font-size: 48px;
  letter-spacing: -1px;
}
h5{
  font-size: 32px;
  /* letter-spacing: -1px; */
}
h6{
  font-size: 24px;
  letter-spacing: -1px;
  font-weight: 400;
}
.p1-24{
  font-size: 24px;
}
.p2{
  font-size: 20px;
}
.p2-medium{
  font-size: 20px;
  font-weight: 500;
}
.p4{
  font-size: 16px;
  font-weight: 400;
}
.p4-medium{
  font-size: 16px;
  font-weight: 500;
}
.p5-medium{
  font-size: 14px;
  font-weight: 500;
}
a {
  text-decoration: none;
}
.btn{font-size: 16px;}

.theme-red-btn{
  background: #FF4755;
  color: #fff !important;
  border-radius: 16px;
  display: inline-block;
  padding: 20px 32px;
  text-align: center;
}
.theme-red-btn:hover {
  background-color: #A80000;
}
.theme-red-btn:active {
  background-color: #FF4755 !important;
}
.theme-pink-btn {
  background-color: #FF4755;
  color: #fff !important;
  padding: 16px 24px;
  border-radius: 16px;
  text-align: center;
}
.theme-pink-btn:hover {
  background-color: #E12937;
}
.theme-pink-btn:active {
  background-color: #FF4755 !important;
}
.theme-light-btn{
  color: #4D535F;
  padding: 12px;
  border-radius: 16px;
  border: 1px solid #4D535F;
  text-align: center;
  display: inline-block;
}
.section{
  padding: 60px 0;
  position: relative;
  background: #F3F4F5;
}
li{
  list-style: none;
  color: #000;
}
.card{
  border-radius: 32px;
}
.modal-open {
  overflow: hidden;
}
.green-text{
  color: #109146;
}
.red-text{
  color: #FF4755;
}
button.theme-dark-btn {
  border: 0;
  height: 56px;
  padding: 16px 32px;
  width: 100%;
  display: block;
  margin-bottom: 15px;
}
button.theme-light-btn {
  width: 100%;
  background: #fff;
}


@media screen and (max-width: 576px) {
  h1{
    font-size: 32px !important;
  }
  .p1-24{
    font-size: 16px;
  }
  h2, h4{
    font-size: 24px;
  }
  .theme-red-btn{
    padding: 16px 32px;
  }
  /* h1,h2,h3, h4 {
      font-size: 32px !important;
  }
  h5 {
      font-size: 24px;
  }
  h6 {
      font-size: 13px;
      font-weight: 100;
  } */
  /* .section{
    padding-top: 0;
  } */
}