@-webkit-keyframes ctaBgAnimate {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 0.1;
    }
}

@keyframes ctaBgAnimate {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 0.1;
    }
}