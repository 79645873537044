.footer-section .row:nth-child(1) .col-md-4:nth-child(1) {
    text-align: center;
}
.footer-section .row:nth-child(1) .col-md-4:nth-child(1) p {
    text-align: left;
}

.footer-section .trust-pilot {
    position: fixed;
    bottom: 20%;
    inset-inline-start: 0;
    z-index: 1;
}
.footer-section .trust-pilot img {
    width: 120px;
}

.footer-content p {
    margin-bottom: 12px;
}
.footer-content ul li {
    list-style: disc;
}

@media screen and (max-width: 576px) {
    .section.footer-section .footer-logo {
        margin-bottom: 24px;
    }
    .footer-social-icons {
        margin-top: 24px;
        margin-bottom: 32px;
    }
    .section.footer-section .row:nth-child(1) > :not(div:nth-child(1)) p {
        margin-bottom: 24px;
    }
    .footer-menu li, .footer-legal-menu li {
        margin-bottom: 8px;
    }
}